import React from "react"
import { graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-awesome-styled-grid"
import { Heading2 } from "../../components/Heading"
import Layout from "../../components/Layout"
import Block from "../../components/Block"
import { Hero, HeroContent, HeroHeading } from "../../components/Hero"
import BackgroundImage from "gatsby-background-image"
import { LinkCta } from "../../components/Button"
import styled from "styled-components"

const DivCenter = styled.div`
  text-align: center;
`

const Background = ({ image, className, children }) => {
  return (
    <BackgroundImage
      className={className}
      fluid={image.childImageSharp.fluid}
      style={{ height: "100%" }}
    >
      {children}
    </BackgroundImage>
  )
}

const CbdOelTest = ({ location, data: { image } }) => {
  return (
    <Layout
      location={location}
      title="CBD Öl Test – das beste CBD Öl finden"
      mt0
      light
    >
      <Hero heightVh={50}>
        <Background image={image}>
          <HeroContent align="flex-end">
            <Container>
              <Row>
                <Col>
                  <HeroHeading>
                    CBD Öl Test – das beste CBD Öl finden
                  </HeroHeading>
                </Col>
              </Row>
            </Container>
          </HeroContent>
        </Background>
      </Hero>
      <Container>
        <Block>
          <Row>
            <Col>
              <p>
                Sie haben CBD Öl von Freunden, Bekannten oder aus der Werbung
                gehört und möchten sich selbst von den{" "}
                <Link to="/ratgeber/produkt-ratgeber/">Vorteilen</Link>{" "}
                überzeugen, um <strong>besser zu schlafen</strong> oder für ein
                besseres Allgemeinwohlsein zu sorgen?
              </p>
              <p>
                Es gibt sehr viele{" "}
                <Link to="/vergleiche/cbd-oel/">Anbieter von CBD Öl</Link> und
                das macht die Entscheidung nicht einfach. Nutzen Sie daher die
                Erfahrungen und die Expertise anderer. Als{" "}
                <Link to="/forschungsmethode/">Experten</Link> in Sachen CBD
                möchten wir Ihnen mit unserer{" "}
                <strong>interaktiven und kostenlosen Produktberatung</strong>{" "}
                dabei helfen{" "}
                <Link to="/vergleiche/cbd-oel/">
                  das richtige CBD Öl zu finden
                </Link>
                . Jedes CBD Öl hat seine ganz eigenen Besonderheiten und es ist
                nicht immer einfach den Überblick zu behalten.
              </p>
              <p>
                In unserem CBD Öl Selbsttest können Sie in wenigen Schritten für
                sich das CBD Öl mit{" "}
                <strong>
                  sehr guter Wirkung, dem besten Geschmack, den besten
                  Erfahrungen, dem höchsten CBD Gehalt oder dem gewünschten
                  Preis
                </strong>{" "}
                finden. Alles ganz nach Ihren Vorstellungen und Präferenzen.
              </p>
              <p>
                Die interaktive Produktberatung beruht auf einem Theoriegerüst
                aus Literatur, Verpackungsangaben, Gebrauchsanweisungen,
                Analysenzertifikate, Verkäuferangaben, Rezensionen von Kunden
                und Befragungen mit Probanden, um Sie bei der
                Entscheidungsfindung zu unterstützen. Bei diesem Selbsttest
                werden CBD Öle verglichen, die ein CBD Gehalt von 10 % pro
                Flasche haben. Insgesamt werden{" "}
                <strong>
                  über 20 Anbieter von CBD Öl miteinander verglichen
                </strong>
                , um das CBD Produkt zu finden, das am besten für Sie passt. Das
                CBD Öl 10% ist das am häufigsten verkaufte CBD Öl und wird für
                Anfänger empfohlen, weil die Dosierung leicht angepasst werden
                kann. Weitere Informationen über die Herangehensweise für die
                richtige Dosierung erhalten Sie{" "}
                <Link to="/vergleiche/cbd-oel/#dosierung">hier</Link>.
              </p>
              <p>
                Der{" "}
                <strong>CBD Öl Selbsttest dauert lediglich 1-2 Minuten</strong>{" "}
                und bildet mit einem geringen zeitlichen Durchführungsaufwand
                eine gute Basis, damit die selbstentwickelten Algorithmen sehr
                gute Ergebnisse liefern können. Dadurch können Sie innerhalb
                kürzester Zeit von den Erfahrungen der CBD Öl Experten und deren
                Kenntnisse über die Thematik profitieren.
              </p>
              <DivCenter>
                <LinkCta to="/ratgeber/fragebogen/">Zum CBD Öl Test</LinkCta>
              </DivCenter>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2>
                Gestaltung des Fragebogens für Ihren Cannabisöl Testsieger im
                persönlichen Selbsttest
              </Heading2>
              <p>
                Für die Ermittlung des optimalen CBD Öls werden in der Befragung
                ausschließlich geschlossene Fragen gestellt. Die
                Antwortmöglichkeiten werden randomisiert vorgegeben, um
                Verzerrungen bei der Beantwortung zu vermeiden.{" "}
                <strong>
                  Der Fragebogen besteht nicht aus zu vielen Fragen
                </strong>
                , um sich nicht in Details zu verfangen. Im Hinblick auf die
                Stringenz und Angemessenheit der Fragen wurde die Befragung im
                Vorfeld mit mehreren Probanden getestet, die noch keine
                Erfahrungen mit CBD haben. Die Fragen sind neutral formuliert
                und bieten keinen Ansatz irgendetwas auszuschließen. Auf schwer
                verständliche Fachterminologien wurde in dem CBD Öl Selbsttest
                bewusst verzichtet, sodass insbesondere{" "}
                <strong>Anfänger bestmöglich beraten werden</strong>. Die
                Probanden bewerteten diese Form interaktive Produktberatung als
                übersichtlich gestaltet und würden es anderen CBD Öl Kunden mit
                hoher Empfehlung weiterempfehlen.
              </p>
              <p>
                Der Fragebogen zur Ermittlung des besten CBD Öls unterteilt sich
                dabei in drei grundlegende Phasen: Einführungsphase, Hauptteil
                und Schlussphase.
              </p>
              <p>
                In der <strong>Einführungsphase</strong> wird nach einer kurzen
                Frage zu dem Hauptnutzen von CBD wie z.B. für einen besseren
                Schlaf erfragt, ob Sie CBD Öl kennen. Beispielsweise in dem Sie
                CBD Öl schon mal konsumiert haben und so bereits ihre ganz
                persönlichen Erfahrungen mit CBD gesammelt haben.
              </p>
              <p>
                In dem <strong>Hauptteil</strong> des CBD Tests gehen wir
                konkret auf Ihren bevorzugten Geschmack für das CBD Öl ein. Der
                Geschmack der verschiedenen Anbieter von CBD Ölen variiert sehr.
                Dabei können die Geschmäcker bitter, angenehm mild oder sogar
                mit Fruchtaromen wie Minze oder Zitrone verfeinert sein.
                Außerdem ermitteln wir Ihre Toleranz zu Lieferzeiten. Im
                Hauptteil geht es zudem um Ihre Wunschvorstellung zur
                Schnelligkeit und Dauer der CBD Wirkung sowie Ihr Involvement
                für die Qualitätsprüfung der Produkte von unabhängigen Laboren
                wie dem{" "}
                <a
                  href="https://www.tuvsud.com/de-de"
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  TÜV Süd
                </a>
                .
              </p>
              <p>
                Des Weiteren ist ein wichtiges Kriterium im Hauptteil Ihre
                persönliche Preissensibilität. CBD Öle sind auf dem ersten Blick
                sehr teuer, daher lohnt sich unbedingt ein Preisvergleich. Die
                meisten CBD Öle kosten zwischen 50 € und 60 €.
              </p>
              <p>
                Abschließend geht es in dem CBD Test in dem sogenannten{" "}
                <strong>Schlussteil</strong> um soziodemographische Daten wie
                Ihr Geschlecht und das Alter, um für Sie das bestmögliche CBD Öl
                zielgruppendefiniert zu ermitteln.
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2>Zusammenfassung CBD Öl Test</Heading2>
              <p>
                Das Ziel des CBD Öl Selbsttest ist es aus einer Vielzahl von CBD
                Ölen zahlreicher Anbieter das{" "}
                <strong>
                  beste CBD Öl nach ihren persönlichen Präferenzen
                </strong>{" "}
                wie beispielsweise Geschmack, Wirkung, Qualität und Preis zu
                finden.
              </p>
              <DivCenter>
                <LinkCta to="/ratgeber/fragebogen/">Zum CBD Öl Test</LinkCta>
              </DivCenter>
            </Col>
          </Row>
        </Block>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    image: file(relativePath: { eq: "stock/oil-water.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default CbdOelTest
